import axios from 'axios'
import router from '@/router/index.js'

let loading = null

// axios.defaults.baseURL = 'http://localhost:7002/web' // 本地mock，这里不能用api/web，因为在nginx做了配置npm
// axios.defaults.baseURL = 'http://47.113.82.234:3001/mock/11/web' // 远程mock
// axios.defaults.baseURL = 'https://www.xiaomeng.art/api/web' // sit
axios.defaults.baseURL = 'https://www.xiaomeng.tech/api/web' // 生产
axios.defaults.withCredentials = true
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.request.use(function (config) {
  // 开loading
  if (config.loading) {
    loading = window.$loading()
  }
  return config
})

axios.interceptors.response.use(res => {
  // 关loading
  if (loading) {
    loading.close()
  }
  // 统一处理
  if (typeof res.data !== 'object') {
    window.$alert('服务器异常，请稍后再试！')
    return Promise.reject(res)
  }
  if (res.data.code === 1001) {
    window.$alert(res.data.msg || '登录超时').then(() => {
      router.push({ path: '/login' })
    })
    return Promise.reject(res)
  }
  if (res.data.code === 1002) {
    window.$alert(res.data.msg || '权限不足')
    return Promise.reject(res)
  }
  if (res.data.code !== 1000) {
    return Promise.reject(res)
  }
  if (res.data.code === 1000) {
    return res.data.data
  }
})

function http (url, params, option = {}) {
  return axios({
    url: url,
    data: params,
    method: option.method || 'post',
    loading: 'loading' in option ? option.loading : true
  })
}

export default http
